<template>
  <component :is="activeStatusComponent" :transaction="transaction"></component>
</template>

<script>
import Success from "@/views/order/status/Success";
import Cancel from "@/views/order/status/Cancel";
import Error from "@/views/order/status/Error";
import {useToast} from "vue-toastification";

export default {
  components: {
    Success,
    Cancel,
    Error,
  },
  data() {
    return {
      transaction: {}
    }
  },
  computed: {
    activeStatusComponent() {
      switch (this.$route.params.status) {
        case "success":
          return "Success";
        case "cancel":
          return "Cancel";
        case "error":
          return "Error";
        default:
          return null;
      }
    }
  },
  mounted() {
    this.fetchTransaction();
    console.log('transaction....')
    console.log(this.transaction)
  },
  methods: {
    async fetchTransaction() {
      const {data} = await window.axios.get(`transaction/` + this.$route.params.transactionToken)
      this.transaction = data.data;
    }
  },
  setup() {
    const toast = useToast();

    return {toast}
  },
}
</script>

<style scoped>

</style>