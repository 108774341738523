<template>
  <div class="text-center lg:text-left">
    <svg class="hidden h-[4.5rem] w-[4.5rem] -ml-2 mb-4 text-red-300 lg:block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
    <h1 class="text-2xl text-red-500 font-extrabold tracking-tight lg:text-3xl lg:text-white">
      Greška prilikom plaćanja
    </h1>
    <div class="mt-4">
      <p class="text-red-900 lg:text-red-50">
        Došlo je do greške prilikom plaćanja.
      </p>
    </div>
    <div class="mt-6">
        <a :href="urls.error" class="text-gray-700 font-bold">
          <span class="underline pb-1">Vrati se nazad na Trguj.me</span>
        </a>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters("order", ["urls"])
  }
}
</script>