<template>
  <div class="text-center lg:text-left">
    <svg class="hidden h-[4.5rem] w-[4.5rem] -ml-2 mb-4 text-amber-200 lg:block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
    <h1 class="text-2xl text-amber-500 font-extrabold tracking-tight lg:text-3xl lg:text-white">
      Otkazano plaćanje
    </h1>
    <div class="mt-4">
      <p class="text-amber-900 lg:text-white">
        Otkazano plaćanje.
      </p>
    </div>
    <div class="mt-6">
      <a :href="urls.success" class="text-gray-700 font-bold">
        <span class="underline pb-1">Vrati se nazad na Trguj.me</span>
      </a>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters("order", ["urls"])
  }
}
</script>