<template>
  <div class="text-center lg:text-left">
    <svg class="hidden h-[4.5rem] w-[4.5rem] -ml-2 mb-4 text-green-200 lg:block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
    <h1 class="text-2xl text-green-500 font-extrabold tracking-tight lg:text-3xl lg:text-white">
      Uspješno plaćanje
    </h1>
    <div class="mt-4">
      <p class="text-green-900 lg:text-white">
        Uspješno plaćeno.
      </p>
    </div>
    <div class="mt-4">
      <div class="lg:text-white">
        <h2 class="text-xl underline">Detalji transakcije</h2>

        <p>
          <span class="font-bold">Broj porudžbine:</span> {{ transaction.order_id }}
        </p>

        <p>
          <span class="font-bold">Naplaćeni iznos:</span> {{ transaction.amount }} EUR
        </p>

        <p>
          <span class="font-bold">Metod plaćanja:</span> {{ transaction.card.toUpperCase() }} *{{ transaction.last_four}}
        </p>

        <p>
          <span class="font-bold">Vrijeme plaćanja:</span> {{ transaction.created_at }}
        </p>
      </div>

    </div>
    <div class="mt-6">
      <a :href="urls.success" class="text-gray-700 font-bold">
        <span class="underline pb-1">Vrati se nazad na Trguj.me</span>
      </a>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    transaction: Object
  },
  computed: {
    ...mapGetters("order", ["urls"])
  }
}
</script>